<template>
  <LiefengContent>
    <template v-slot:title> 居民管理 </template>
    <template v-slot:toolsbarLeft>
      <LiefengTree
        ref="mychild"
        treeID="mychild"
        fixedContentID="contentarea"
        :fixedmenu="true"
        @handleData="getList"
        style="width: 200px"
      ></LiefengTree>
    </template>
    <template v-slot:toolsbarRight>
      <Input
      :maxlength="20"
        enter-button
        v-model.trim="searchData.name"
        placeholder="姓名"
        style="margin-right:10px;width: 150px"
        @on-enter="getList"
      />
      <Input
      :maxlength="20"
        enter-button
        width="100px"
        v-model.trim="searchData.phone"
        placeholder="手机号"
        style="margin-right:10px;width:150px"
        @on-enter="getList"
      />
       <Button type="primary" icon="ios-search" @click="getList">搜索</Button>
      <Button type="success" icon="ios-refresh" @click="rest">重置</Button>
       <Button
        @click="exportExcel"
        type="error"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
      <Button type="warning" icon="ios-mail" @click="sendMsg">发消息</Button>
      <Icon
        type="md-more"
        @click="selectQuery = true"
        style="font-size: 24px; line-height: 32px; cursor: pointer"
        title="更多查询条件"
      />

      <Drawer
          title="查询条件"
          v-model="selectQuery"
          width="320"
          :styles="{
            height: 'calc(100% - 55px)',
            overflow: 'auto',
            paddingBottom: '53px',
            position: 'static',
          }"
        >
          <Form :model="searchData" :label-colon="true">
            <FormItem label="姓名">
              <Input
                v-model.trim="searchData.name"
                placeholder="请输入姓名"
                :maxlength="20"
              ></Input>
            </FormItem>
            <FormItem label="手机号">
              <Input
                :maxlength="20"
                v-model.trim="searchData.phone"
                placeholder="请输入手机号"
              ></Input>
            </FormItem>
            <FormItem label="住房编号">
              <Input
                :maxlength="20"
                v-model.trim="searchData.familyNo"
                placeholder="请输入档案编号"
                style="width: 100%"
              />
            </FormItem>
            <FormItem label="身份类型">
               <Select v-model="searchData.userType" placeholder="请选择身份类型" clearable>
                  <Option value="4">租赁人员</Option>
                  <Option value="3">是业主且户籍在此</Option>
                  <Option value="2">是业主但户籍不在此</Option>
                  <Option value="1">仅户籍在此</Option>
                  <Option value="5">其他</Option>
              </Select>
            </FormItem>
            <FormItem label="详细地址">
              <Input
                :maxlength="20"
                v-model.trim="searchData.detailAddr"
                placeholder="请输入详细地址"
                style="width: 100%"
              />
            </FormItem>
             <FormItem label="家庭联络人">
               <Select v-model="searchData.contact" placeholder="请选择是否家庭联络人" clearable>
                  <Option value="1">是</Option>
                  <Option value="2">否</Option>
              </Select>
            </FormItem>
          </Form>
          <div class="demo-drawer-footer">
            <Button style="margin-right: 8px" @click="selectQuery = false"
              >取消</Button
            >
            <Button type="primary" @click="getList">查询</Button>
          </div>
        </Drawer>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :loading="loading"
        :fixTable="true"
        :curPage="pageNum"
        :total="total"
        :pagesizeOpts="[20, 30, 50, 100]"
        :page-size="pageSize"
        @hadlePageSize="getList"
        ref="baseList"
      ></LiefengTable>
      <!-- 发消息 -->
      <LiefengModal
        :value="msgModal"
        title="发消息"
        width="800px"
        height="calc(100vh - 200px)"
        @input="sentMsgModal"
      >
        <template v-slot:contentarea>
          <Form
            :model="msgform"
            :label-width="80"
            ref="msgform"
            :rules="ruleValidate"
          >
            <formItem
              ><Button type="primary" @click="selectUser"
                >选择接收人</Button
              ></formItem
            >
            <FormItem label="接收人" prop="recIds"
              ><Input
              :maxlength="400"
                disabled
                v-model.trim="selectName"
                type="textarea"
                show-word-limit
                :rows="2"
                placeholder="请选择接收人"
              ></Input
            ></FormItem>
            <FormItem label="标题" prop="title"
              ><Input
                v-model.trim="msgform.title"
                placeholder="请输入标题"
                maxlength="30"
                show-word-limit
              ></Input
            ></FormItem>
            <FormItem label="内容" prop="content"
              ><Input
                v-model.trim="msgform.content"
                type="textarea"
                :maxlength="800"
                show-word-limit
                :rows="8"
                placeholder="请输入内容"
              ></Input
            ></FormItem>
             <FormItem label="短信方式">
                  <RadioGroup v-model="msgform.sendChannel">
                    <Radio label="1">是</Radio>
                    <Radio label="2">否</Radio>
                  </RadioGroup>
                </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="sendRest" style="margin-right: 10px">清空</Button>
           <Button type="primary" @click="sendOK('msgform')">确定</Button>
        </template>
      </LiefengModal>
      <!--选择接收人-->
      <LiefengModal
        :value="selectModal"
        title="选择接收人"
        width="1300px"
        height="calc(100vh - 200px)"
        @input="selectStatus"
      >
        <template v-slot:contentarea>
          <selectUser
            ref="selchildren"
            @handleSelect="selectData"
            @communityCode="communityCodeFn"
          ></selectUser>
        </template>
        <template v-slot:toolsbar>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="saveUser"
            >保存</Button
          >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/peolistindex")
import LiefengContent from "@/components/LiefengContent3";
import LiefengTree from "@/components/LiefengTree";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from '@/components/LiefengModal'
import selectUser from "@/views/peolist/childrens/selectUser";
export default {
  watch: {
    selectQuery(newV) {
      if (!newV) {
        this.searchData.name = '';
            this.searchData.phone = '';
            this.searchData.familyNo = '';
            this.searchData.userType = '';
            this.searchData.detailAddr = '';
            this.searchData.contact = '';
      }
    },
  },
  data() {
    return {
      // 查询更多
      selectQuery: false,
      //表格加载loading
      loading: false,
      communityCode: "",
      nocommunityCode: "",
      total: 0,
      treeData: [],
      msgModal: false,
      selectModal: false,
      msgform: {
         sendChannel: '2',
        businessCode: "0000", //业务代码 0000:发送系统信息
        businessType: 5, // 业务类型5:发送系统信息
        oemCode: parent.vue.oemInfo.oemCode,
        recIds: "",
        title: "",
        content: "",
        sendId: parent.vue.loginInfo.userinfo.custGlobalId,
      },
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "住房编号",
          key: "familyNo",
          width: 100,
          align: "center",
        },
        {
          title: "姓名",
          key: "name",
          width: 100,
          align: "center",
        },
        {
          title: "性别",
          key: "sexLabel",
          width: 80,
          align: "center",
        },
        {
          title: "联系电话",
          key: "phone",
          width: 130,
          align: "center",
        },
        {
          title: "身份类型",
          key: "userTypeLabel",
          width: 120,
          align: "center",
        },
        {
          title: "入驻时间",
          key: "gmtCreate",
          width: 180,
          align: "center",
        },
        {
          title: "所在详细地址",
          key: "detailAddr",
          minWidth: 150,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                  domProps: {
                    title: params.row.roadName + params.row.detailAddr,
                  },
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                    },
                  },
                },
                params.row.roadName + params.row.detailAddr
              ),
            ]);
          },
        },

        {
          title: "是否家庭联络人",
          key: "contactLabel",
          width: 140,
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: 80,
          render: (h,params) => {
            return h('div',[
              h('Button',{
                props: {
                  type: 'info',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.remove(params.row)
                  }
                }
              },'删除')
            ])
          }
        },
      ],
      tableData: [],
      selection: [],
      showDo: true,
      hideEdit: true,
      hidenBread: true,
      hideDel: false,
      hideSearch: false,
      pageNum: 1,
      pageSize: 20,
      orgCode: "",
      searchData: {
        name: "",
        phone: "",
        pageSize: 20,
        pageNum: 1,
        familyNo: '',
        userType: '',
        detailAddr: '',
        contact: ''
      },
      selectId: [],
      selectName: "",
      ruleValidate: {
        recIds: [
          {
            required: true,
            message: "接收人不能为空",
            trigger: "blur",
          },
        ],
        title: [
          {
            required: true,
            message: "标题不能为空",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "内容不能为空",
            trigger: "blur",
          },
        ],
      },
      treeParams: {
        status: 2, //必填
        roadCode: "",
        projectCode: "",
        communityCode: "", //必填
        pageSize: 20,
        page: 1,
        name: "",
        phone: "",
        oemCode: "", //必填  缓存拿
      },
      pagess: 1,
    };
  },
  components: {
    LiefengContent,
     LiefengTree,
    LiefengTable,
    selectUser,
    LiefengModal
  },
  methods: {
    //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "住房编号",
            "姓名",
            "性别",
            "联系电话",
            "身份类型",
            "入驻时间",
            "所在详细地址",
            "是否家庭联络人"
          ];
          let filterVal = [
            "familyNo",
            "name",
            "sexLabel",
            "phone",
            "userTypeLabel",
            "gmtCreate",
            "detailAddr",
            "contactLabel",
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "居民列表"
          );
        },
      });
    },
    //发消息弹窗状态改变
    sentMsgModal(status) {
      this.msgModal = status;
    },
    //选择接收人弹窗状态改变
    selectStatus(status) {
      this.selectModal = status;
    },
    communityCodeFn(data) {
      this.nocommunityCode = data;
    },
    getList(data) {
      this.loading = true;
      if (data) {
        if (data.communityCode) {
          this.tableData = [];
          this.pageNum = 1;
          this.pageSize = this.pageSize;
          this.treeParams = {
            ...data,
            status: 2, //必填
            name: this.searchData.name,
            phone: this.searchData.phone,
            oemCode:parent.vue.oemInfo.oemCode,
            page: data.page,
            pageSize: data.pageSize,
          };
          this.pageNum = data.page;
          this.pageSize = data.pageSize;
        }
        if (data.page) {
          //切换页面时
          this.treeParams.page = data.page;
          this.treeParams.pageSize = data.pageSize;
          this.treeParams.name = this.searchData.name;
          this.treeParams.phone = this.searchData.phone;
          this.treeParams.familyNo = this.searchData.familyNo;
          this.treeParams.userType = this.searchData.userType;
          this.treeParams.detailAddr = this.searchData.detailAddr;
          this.treeParams.contact = this.searchData.contact;
        }
        if (data.cancelable) {
          //点击查询时  查询返回第一页的数据
          this.treeParams.page = 1;
           this.treeParams.name = this.searchData.name;
          this.treeParams.phone = this.searchData.phone;
          this.treeParams.familyNo = this.searchData.familyNo;
          this.treeParams.userType = this.searchData.userType;
          this.treeParams.detailAddr = this.searchData.detailAddr;
          this.treeParams.contact = this.searchData.contact;
          this.pageNum = 1;
        }
      } else {
        this.treeParams = {
          communityCode: this.treeParams.communityCode,
          roadCode: this.treeParams.roadCode,
          status: 2, //必填
          name: this.searchData.name,
          phone: this.searchData.phone,
          familyNo: this.searchData.familyNo,
          userType: this.searchData.userType,
          detailAddr: this.searchData.detailAddr,
          contact: this.searchData.contact,
          oemCode: parent.vue.oemInfo.oemCode,
          page: this.pageNum,
          pageSize: this.pageSize,
        };
      }
      this.$get(
        "/syaa/pc/sy/user/residents/selectSyFamilyMemberPage",
        {...this.treeParams}
      )
        .then((res) => {
          if (res.code === "200") {
            this.tableData = res.dataList;
            this.total = res.maxCount;
            this.pageNum = res.currentPage;
            this.selectQuery = false;
             this.searchData.name = '';
            this.searchData.phone = '';
            this.searchData.familyNo = '';
            this.searchData.userType = '';
            this.searchData.detailAddr = '';
            this.searchData.contact = '';
            this.loading = false;
          }else {
            this.loading = false;
            this.$Message.error({
              background: true,
              content: res.desc
            })
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    setSelection(selection) {
      this.selection = selection;
    },
    rest() {
      this.searchData.phone = "";
      this.searchData.name = "";
       this.searchData.familyNo = "";
      this.searchData.userType = "";
      this.searchData.detailAddr = "";
      this.searchData.contact = "";
      this.pageNum = 1;
      this.getList();
    },
    getTableData(data) {
      this.tableData = data.dataList;
      this.total = data.maxCount;
    },
    remove(row) {
      // 为了在删除最后一页的最后一条数据时能成功跳转回最后一页的上一页
      let totalPage = Math.ceil((this.total - 1) / this.pageSize); // 总页数
      this.pageNum = this.pageNum > totalPage ? totalPage : this.pageNum;
      this.pageNum = this.pageNum < 1 ? 1 : this.pageNum;


      if (row.familyMemberId) {
         this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认删除数据</p>",
          cancelText: "取消",
          okText: "确认",
          onOk: () => {
        this.$post("/syaa/pc/sy/user/residents/delFamilyMember", {
          familyMemberId: row.familyMemberId,
        })
          .then((res) => {
            if (res.code === "200") {
              this.$Message.success("删除成功");
              let data = {
                pageSize: this.pageSize,
                pageNum: this.pageNum,
              };
              this.getList(data);
            }
          })
          .catch((err) => console.log(err));
          }
          })
      }
    },
    sendMsg() {
      this.msgModal = true;
    },
    selectUser() {
      this.selectModal = true;
    },
    selectData(data) {
      let temp = [];
      let tempId = [];
      data.forEach((item) => {
        tempId.push(item.custGlobalId);

        temp.push(item.title);
      });
      this.selectName = temp.join(",");
      this.selectId = tempId;
    },

    saveUser() {
      this.communityCode = this.nocommunityCode;
      this.selectModal = false;
      this.selectId = Array.from(new Set(this.selectId)); // 去重

      this.msgform.recIds = this.selectId.join(",");
    },
    sendOK(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$post(
            "/sendmsg/pc/chat/message/createSyMessagePoint",
            {
              ...this.msgform,
              communityCode: this.communityCode,
              orgCode: parent.vue.loginInfo.userinfo.orgCode
            }
          )
            .then((res) => {
              if (res.code === "200") {
                if(this.msgform.sendChannel == '1') {
                  this.$Message.success("发送成功");
                }else {
                  this.$Message.success("操作成功")
                }
                this.msgModal = false;
                this.msgform.recIds = "";
                this.msgform.title = "";
                this.msgform.content = "";
                this.selectName = "";
                this.selectId = [];
                this.$refs.selchildren.removeAll();
              }else {
                 this.$Message.error(res.desc);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    sendRest() {
      this.msgform.title = "";
      this.msgform.content = "";
    },
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-drawer-body {
  .ivu-form-item {
    width: 100%;
     margin-bottom: 0;
  }
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>